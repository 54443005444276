<app-about-service></app-about-service>
<app-events-content></app-events-content>
<app-login-registration></app-login-registration>

<div class="main-view fixed-bottom mb-lg-4 mb-xl-5 mb-1">
    <div class="container-fluid d-none">
        <div class="d-flex justify-content-center flex-column p-3">
            <div class="card event-block-v"
                 *ngFor="let event of events; let i = index"
                 [class.hidden-event]="!event.isVisibleOnMainPage">
                <app-event [event]="event"
                           [isMainPage]="true"
                           [ngClass]="{ 'hide-on-iphone5': !i }"></app-event>
            </div>
        </div>
    </div>

    <div class="container-fluid pl-lg-5 pr-lg-5">
        <div class="d-flex align-items-end pb-3 content-wrapper">
            <div class="p-2 title">
                <span innerHTML="{{ 'main-view.title' | translate }}"></span>
                <a
                        href="#aboutService"
                        data-toggle="modal"
                        class="title-link"
                >{{ 'main-view.title2' | translate }}</a>
            </div>
<!--            <div class="ml-auto event-block-h-all">-->
<!--                <div class="card event-block-h"-->
<!--                     *ngFor="let event of events"-->
<!--                     [class.hidden-event]="!event.isVisibleOnMainPage">-->
<!--                    <app-event [event]="event"-->
<!--                               [isMainPage]="true"></app-event>-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <div class="d-flex align-items-center mt-2 mt-lg-5 footer-wrapper">
            <div appAuth [hideIfNotAuthenticated]="false" class="p-2">
                <button type="button" class="btn button-red" data-toggle="modal" data-target="#authRegModal">
                    {{ 'loginButtonLabel' | translate }}
                </button>
            </div>
            <div class="p-md-4 p-0 remark" [class.marginWhenAuthenticated]="authService.isAuthenticated()">
                <span innerHTML="{{ 'main-view.remark' | translate }}"></span>
            </div>
            <div class="d-none d-lg-block copyright ml-auto p-2">
                <span innerHTML="{{ 'main-view.copyright' | translate }}"></span>
            </div>
        </div>
    </div>
</div>
